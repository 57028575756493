import { create } from "zustand";
import { produce } from "immer";
import { v4 as uuid } from "uuid";

import { NUM_PAIRS, NUM_COMPARISON_SELECTIONS } from "@tinker-tots/shared/constants.js";
import { type IClientCard as ICard} from "@tinker-tots/shared/types.js";
import { NUM_SURVEY_QUESTIONS, ESSENTIAL_SURVEY_QUESTIONS, NUM_SURVEY_QUESTIONS_TO_ASK } from "@tinker-tots/shared/constants.js";
import { shuffleArray } from "@tinker-tots/shared/randomisation.js";

import { 
  createUser, 
  selectCard, 
  selectCardFromPair, 
  markAnswered, 
  setPresentationTimeStamp, 
  setTaskDescriptionShown, 
  giveConsent, 
  feedback, 
  createSession, 
  getSession, 
  createSurvey, 
  getSurvey, 
  completeSurvey,
  setResults,
} from "../api.js";

const drawSurveyQuestions = () => {
  const questions = Array(NUM_SURVEY_QUESTIONS)
    .fill(0)
    .map((q, ii) => ii + 1);

  const NUM_ESSENTIAL_QUESTIONS = ESSENTIAL_SURVEY_QUESTIONS.length;
  const optionalQuestions = questions.filter(
    (q) => !ESSENTIAL_SURVEY_QUESTIONS.includes(q)
  );
  if (NUM_SURVEY_QUESTIONS_TO_ASK < NUM_ESSENTIAL_QUESTIONS) {
    throw new Error(
      "NUM_SURVEY_QUESTIONS_TO_ASK must be greater than or equal to the number of essential questions"
    );
  }
  if (NUM_SURVEY_QUESTIONS_TO_ASK > NUM_SURVEY_QUESTIONS) {
    throw new Error(
      "NUM_SURVEY_QUESTIONS_TO_ASK must be less than or equal to the total number of questions"
    );
  }

  const numOptionalQuestionsToAsk =
    NUM_SURVEY_QUESTIONS_TO_ASK - NUM_ESSENTIAL_QUESTIONS;
  return shuffleArray(optionalQuestions)
    .slice(0, numOptionalQuestionsToAsk)
    .concat(ESSENTIAL_SURVEY_QUESTIONS)
    .sort((a, b) => a - b);
};

interface IStore {
  userId: string;
  userPending: boolean;
  userPromise: Promise<void>;
  loading: boolean;
  sessionId: string;
  sessionPromise: Promise<void>;
  consent: boolean;
  consentTimeStamp: Date;
  pairs: ICard[][];
  comparisons: ICard[];
  selections: string[];
  surveyExists: boolean;
  surveyCompleted: boolean;
  surveyQuestions: number[];
  surveyFirst: boolean;
  pairsFirst: boolean;
  cardSectionOrder: string[];
  instructionsViewed: boolean;
  triggerWarningViewed: boolean;
  feedbackLocation: string;
  resetAttempted: boolean;
  resultsUploaded: boolean;
  initUser: () => Promise<void>;
  createUser: () => Promise<void>;
  createSession: (userId?: string) => Promise<void>;
  validateSession: (args: {userId: string, sessionId: string}) => Promise<void>;
  updateSession: (args: {sessionId: string, pairs: ICard[][], comparisons: ICard[]}) => void;
  resetSession: () => Promise<void>;
  createSurvey: (args: {userId: string, sessionId: string}) => Promise<void>;
  validateSurvey: (args: {userId: string, sessionId: string}) => Promise<void>;
  completeSurvey: (args: {answers: {index: number, questionId: string, question: string, answer: string | number | boolean | string[], created: number}[]}) => Promise<void>;
  selectCard: (args: { setIndex: number; subsetIndex: number; targetValue: boolean }) => Promise<void>;
  selectCardFromPair: (args: { pairNumber: number; chosen: number }) => Promise<void>;
  selectCardFromComparison: (args: { chosen: number }) => Promise<void>;
  markAnswered: (args: { setIndex: number; subsetIndex: number[] }) => Promise<void>;
  setPresentationTimeStamp: (args: { setIndex: number; presentationTimeStamp: Date }) => Promise<void>;
  giveConsent: () => Promise<void>;
  createFeedback: (args: { page: string, message: string }) => Promise<void>;
  setInstructionsViewed: () => void;
  setTriggerWarningViewed: () => void;
  setFeedbackLocation: (args: { location: string }) => void;
  setResults: (args: { userId: string, sessionId: string, beforeSurvey: boolean, genderPreference: number, attribute1AboveAverageScore: number, attribute1BelowAverageScore: number, attribute2AboveAverageScore: number, attribute2BelowAverageScore: number, dilemma1Score: number, dilemma2Score: number }) => Promise<void>;
}

export const useStore = create<IStore>((set, get) => ({
  userId: "",
  userPending: false,
  userPromise: Promise.resolve(),
  sessionId: "",
  sessionPromise: Promise.resolve(),
  surveyExists: false,
  surveyCompleted: false,
  surveyQuestions: [],
  surveyFirst: null,
  consent: false,
  consentTimeStamp: null,
  pairs: [],
  comparisons: [],
  selections: [],
  pairsFirst: null,
  cardSectionOrder: [],
  loading: true,
  instructionsViewed: false,
  triggerWarningViewed: false,
  feedbackLocation: "",
  resetAttempted: false,
  resultsUploaded: false,
  async initUser() {
    const { 
      userPending, 
      createUser, 
      createSession, 
      validateSurvey,
    } = get();
    if (userPending) return;

    const userId = localStorage.getItem("userId");
    if (!userId) {
      const userPromise = createUser();
      set({ userPromise });
      return ;
    }
    set({ userId });
    
    // always create a new session
    createSession(userId).then(() => validateSurvey({ userId, sessionId: get().sessionId }))

    return;

    /* old code that may be useful if we need to keep state across reloads in development
    const sessionId = sessionStorage.getItem("sessionId");
    if (!sessionId) {
      await createSession(userId); // Pass userId explicitly
      return;
    }
  
    await validateSession({ userId, sessionId });
    if (!surveyExists) {
      await get().validateSurvey({ userId, sessionId });
    }
    */
  },
  
  async createUser() {
    console.log('creating user')
    const userId = uuid();
    set({ userId, userPending: true, loading: true});

    const response = await createUser({ _id: userId, userAgent: navigator.userAgent, userPreviousSite: document.referrer || "unknown" });
    if (!response.success){
      console.error("Failed to create user:", response.error);
      localStorage.removeItem("userId");
      sessionStorage.clear();
    }
    localStorage.setItem("userId", userId);
    set({ userPending: false });
    get().updateSession(response.data);
    const { sessionId } = get();
    get().createSurvey({ userId, sessionId });
  },
  
  async createSession(userId = get().userId) { // Default to using userId from state if not provided
    console.log('creating session')
    set({ loading: true });
    const response = await createSession({ userId });
    if (!response.success){
      if (response.error === "User not found") {
        localStorage.removeItem("userId");
        await get().initUser();
        return;
      }
      if (!get().resetAttempted){
        set({ resetAttempted: true });
        await get().resetSession();
      }
    } else {
      get().updateSession(response.data);
    }
    return;
  },
  
  async validateSession({ userId, sessionId }) {
    set({ loading: true });
    const sessionResponse = await getSession({ userId, sessionId });
    if (!sessionResponse.success) {
      throw new Error("Failed to get session: " + sessionResponse.error);
    }
    get().updateSession({ sessionId, ...sessionResponse.data });
  },

  updateSession({ sessionId, pairs, comparisons }) {
    const pairsFirst = comparisons[0].phaseOrder === 2;
    sessionStorage.setItem("sessionId", sessionId);
    set({ sessionId, pairs, comparisons, pairsFirst, loading: false, resetAttempted: false, resultsUploaded: false });
  },
  async resetSession() {

    set({ sessionId: null, pairs: null, comparisons: null, pairsFirst: null, loading: true });
    sessionStorage.clear();
    const sessionPromise = get().createSession();
    set({ sessionPromise });
    await sessionPromise;
    get().validateSurvey({ userId: get().userId, sessionId: get().sessionId });
    return;
  },

  async createSurvey({userId, sessionId}) {
    const response = await createSurvey({ userId, sessionId });
    if (!response.success) {
      throw new Error("Failed to create survey: " + response.error);
    }
    const { surveyFirst } = response.data;
    set({ surveyExists: true, surveyFirst, surveyQuestions: drawSurveyQuestions() });
  },

  async validateSurvey({ userId, sessionId }) {
    if (!sessionId) return;
    const response = await getSurvey({ userId, sessionId });
    if (!response.success) {
      throw new Error("Failed to get survey: " + response.error);
    }
    const surveyCompleted = response.data?.elements?.length > 0;
    const { surveyFirst } = response.data;
    set({ surveyExists: true, surveyCompleted, surveyFirst, surveyQuestions: drawSurveyQuestions() });
  },

  async completeSurvey({ answers }) {
    const { userId, sessionId } = get();
    const response = await completeSurvey({ userId, sessionId, answers });
    if (!response.success) {
      throw new Error("Failed to complete survey: " + response.error);
    }
    set({ surveyCompleted: true });
  },

  async selectCard({
    setIndex,
    subsetIndex,
    targetValue,
  }: {
    setIndex: number;
    subsetIndex: number;
    targetValue: boolean;
  }) {
    const {sessionId, consent, consentTimeStamp, comparisons, pairs} = get();
    const isComparison = setIndex === NUM_PAIRS;
    const originalValue = isComparison ? comparisons[subsetIndex].chosen : pairs[setIndex][subsetIndex].chosen;
    const updateToTarget = (state: IStore, targetValue: boolean) => {
      const updateElement = isComparison ? state.comparisons[subsetIndex] : state.pairs[setIndex][subsetIndex];
      updateElement.chosen = targetValue;
    }
    set(produce((state) => updateToTarget(state, targetValue)));
    const response = await (isComparison ? selectCard({ sessionId, setIndex, subsetIndex, consent, consentTimeStamp, chosen: targetValue }) : selectCardFromPair({ sessionId, setIndex, subsetIndex, consent, consentTimeStamp }));
    if (!response.success) {
      set(produce((state) => set(produce((state) => updateToTarget(state, originalValue)))));
    }
  },
  async selectCardFromPair({
    pairNumber,
    chosen,
  }: {
    pairNumber: number;
    chosen: number;
  }) {
    const {selectCard, pairs} = get();
    if (pairNumber === undefined || pairNumber < 0 || pairNumber >= pairs.length)
      throw new Error("Invalid pairNumber:" + pairNumber);
    if (chosen < 0 || chosen >= pairs[pairNumber].length)
      throw new Error(`Invalid selection. Chose ${chosen} of ${pairs[pairNumber].length}.`);
    const targetValue = true;
    const setIndex = pairNumber;
    const subsetIndex = chosen;
    
    await selectCard({ setIndex, subsetIndex, targetValue });
  },

  async selectCardFromComparison({
    chosen,
  }: { chosen: number }) {
    const { selectCard, comparisons } = get();
    if (chosen < 0 || chosen >= comparisons.length)
      throw new Error(`Invalid selection. Chose ${chosen} of ${comparisons.length}.`);
    
    const targetValue = ! comparisons[chosen].chosen;
    if (targetValue === true && comparisons.filter(c => c.chosen).length >= NUM_COMPARISON_SELECTIONS) {
      throw new Error(`Cannot select more than ${NUM_COMPARISON_SELECTIONS} comparisons.`);
    }
    const setIndex = NUM_PAIRS;
    const subsetIndex = chosen;
    await selectCard({ setIndex, subsetIndex, targetValue });
  },

  async markAnswered({ setIndex, subsetIndex }) {
    const { sessionId, consent, consentTimeStamp } = get();
    set(produce(state => {
      if (setIndex === NUM_PAIRS) {
        state.comparisons.forEach(c => c.answered = Date.now());
      } else {
        state.pairs[setIndex].forEach(p => p.answered = Date.now());
      }
    }))
    await markAnswered({ sessionId, setIndex, subsetIndex, consent, consentTimeStamp });
  },

  async setPresentationTimeStamp({ setIndex, presentationTimeStamp }) {
    const { sessionId } = get();
    await setPresentationTimeStamp({ sessionId, setIndex, presentationTimeStamp });
  },

  async setTaskDescriptionShown({ setIndex }) {
    const { sessionId } = get();
    await setTaskDescriptionShown({ sessionId, setIndex });
  },
  async giveConsent() {
    const consentTimeStamp = new Date();
    set(
      produce((state) => {
        state.consent = true;
        state.consentTimeStamp = consentTimeStamp;
      })
    );
    const response = await giveConsent({ userId: (get() as {userId: String}).userId, consent: true, consentTimeStamp });
    if (!response.success) {
      set(
        produce((state) => {
          state.consent = false;
          state.consentTimeStamp = null;
        })
      );
    }
  },

  async createFeedback({ page, message }: { page: string, message: string }) {
    const { userId, sessionId } = get() as {userId: string, sessionId: string};
    const response = await feedback({ userId, sessionId, page, message });
    if (!response.success) {
      throw new Error("Failed to submit feedback: " + response.error);
    }
  },
  setInstructionsViewed(){
    set({ instructionsViewed: true })
  },
  setTriggerWarningViewed(){
    set({ triggerWarningViewed: true })
  },
  setFeedbackLocation({ location }) {
    console.log("Setting feedback location to", location);
    set({ feedbackLocation: location });
  },
  async setResults({
    genderPreference,
    attribute1AboveAverageScore,
    attribute1BelowAverageScore,
    attribute2AboveAverageScore,
    attribute2BelowAverageScore,
    dilemma1Score,
    dilemma2Score,
  }) {
    const { userId, sessionId, surveyFirst, resultsUploaded } = get();
    if (!resultsUploaded) {
      await setResults({
        userId,
        sessionId,
        beforeSurvey: !surveyFirst,
        genderPreference,
        attribute1AboveAverageScore,
        attribute1BelowAverageScore,
        attribute2AboveAverageScore,
        attribute2BelowAverageScore,
        dilemma1Score,
        dilemma2Score,
      });
      set({ resultsUploaded: true });
    }
    return Promise.resolve();
  },
}))
