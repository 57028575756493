import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button } from "../components/Button";
import { useStore } from "../hooks/useStore";
import { useModalStore } from "../hooks/useModalStore";
import { useFlip } from "../hooks/useFlip";
import { Card } from "../components/Card";
import { NUM_PAIRS } from "@tinker-tots/shared/constants";
import { Loading } from "../components/Loading";
import { BeforeLeaveModal } from "./BeforeLeaveModal.jsx";

export function GamePhase2() {
  const { nextPath } = useLoaderData();
  const blockNavigationRef = useRef(true);
  const [descriptionShown, setDescriptionShown] = useState(false);
  const { flip, isFront, toFront, isFlipping } = useFlip();
  const loading = useStore((state) => state.loading);
  const comparisons = useStore((state) => state.comparisons);
  const selectCard = useStore((state) => state.selectCardFromComparison);
  const setPresentationTimeStamp = useStore(
    (state) => state.setPresentationTimeStamp
  );
  const markAnswered = useStore((state) => state.markAnswered);
  const setTaskDescriptionShown = useStore(
    (state) => state.setTaskDescriptionShown
  );
  const triggerWarningViewed = useStore((state) => state.triggerWarningViewed);
  const openTriggerWarningModal = useModalStore(
    (state) => state.openTriggerWarningModal
  );
  const navigate = useNavigate();
  const { t } = useTranslation("game2");

  const onNext = useCallback(() => navigate(nextPath), [navigate, nextPath]);
  const completeAndNext = useCallback(() => {
    blockNavigationRef.current = false;
    onNext();
  }, [onNext]);

  const comparisonCompleted = comparisons.every((c) => !!c.answered);
  useEffect(() => {
    if (comparisonCompleted) {
      completeAndNext();
    }
  }, [comparisonCompleted, completeAndNext]);

  useEffect(() => {
    if (!triggerWarningViewed) {
      openTriggerWarningModal();
    }
  }, [triggerWarningViewed, openTriggerWarningModal]);

  useEffect(() => {
    setPresentationTimeStamp({
      setIndex: NUM_PAIRS,
      presentationTimeStamp: Date.now(),
    });
  }, [setPresentationTimeStamp]);

  const showDescriptionHandler = () => {
    if (!descriptionShown) {
      setTaskDescriptionShown({ setIndex: NUM_PAIRS });
      setDescriptionShown(true);
    }
    flip();
  };

  const nextHandler = () => {
    const notSelected = comparisons.reduce((acc, c, ii) => {
      if (!c.chosen) {
        acc.push(ii);
      }
      return acc;
    }, []);
    markAnswered({ setIndex: NUM_PAIRS, subsetIndex: notSelected });
    completeAndNext();
  };
  if (loading) return <Loading />;
  return (
    <>
      <BeforeLeaveModal enableBlockerRef={blockNavigationRef} />
      <div className="flex flex-col items-center sm:w-1248px mx-auto py-8 sm:py-24px">
        <div className="font-sans text-4.5 leading-6 w-343px sm:w-full sm:text-6 sm:leading-8 font-700 text-center text-tinkerTots-black">
          {t("title")}
        </div>
        <div className="flex flex-wrap justify-center items-center sm:w-1248px py-8 mx-auto">
          {comparisons.map((c, ii) => (
            <React.Fragment key={ii}>
              <div key={`desktop-${ii}`} className="hidden sm:block">
                <Card
                  key={`desktop-card-${ii}`}
                  {...c}
                  onSelect={() =>
                    selectCard({ chosen: ii }).catch(console.error)
                  }
                  isFront={isFront}
                  isFlipping={isFlipping}
                  toFront={toFront}
                  size="small"
                  data-testid={`card-${ii}`}
                />
              </div>
              <div key={`mobile-${ii}`} className="sm:hidden">
                <Card
                  key={`mobile-card-${ii}`}
                  {...c}
                  onSelect={() =>
                    selectCard({ chosen: ii }).catch(console.error)
                  }
                  isFront={isFront}
                  isFlipping={isFlipping}
                  toFront={toFront}
                />
                <div className="h-2 w-full" />
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="flex sm:flex-row flex-col w-343px sm:w-442px max-sm:h-88px justify-between items-center">
          <Button
            key="secondary"
            secondary
            className="w-full sm:w-224px sm:text-4.5"
            text={t(
              `${
                isFront
                  ? "secondary-button-label"
                  : "secondary-button-alternate-label"
              }`
            )}
            onClick={showDescriptionHandler}
            disabled={isFlipping}
          />
          <Button
            key="primary"
            className="w-full sm:w-203px sm:text-4.5"
            text={t("button-label")}
            onClick={nextHandler}
            disabled={!comparisons.some((c) => c.chosen)}
          />
        </div>
      </div>
    </>
  );
}
