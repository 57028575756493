import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";

import { Button } from "./Button";
import { useFlip } from "../hooks/useFlip";
import {
  attributesMap,
  chanceLevelImages,
  genderImages,
  Preceding,
} from "@tinker-tots/shared/constants";
import "../styles/animations.css";
import "../styles/colors.css";

// Helper function to decode HTML entities
// Needed because some i18next interpolations don't handle HTML entities correctly
function decodeHtmlEntities(text) {
  const textArea = document.createElement("textarea");
  textArea.innerHTML = text;
  return textArea.value;
}
const forTranslation = (s) => s.toLowerCase().replace(/ /g, "-");

export function CardPair({ pair, onSelect, onShowDescription }) {
  const [fadingOut, setFadingOut] = useState(false);
  const { t } = useTranslation("card");
  const { flip, isFront, isFlipping, toFront } = useFlip();

  const showDescriptionHandler = () => {
    if (isFront) {
      onShowDescription();
    }
    flip();
  };

  const selectHandler = (index) => {
    if (fadingOut) return;
    setFadingOut(true);
    setTimeout(() => {
      onSelect(index);
      setFadingOut(false);
    }, 450);
  };

  return (
    <>
      <div className="flex flex-col sm:flex-row justify-between max-sm:h-548px max-sm:my-6 sm:py-8">
        <div className={`w-342px sm:w-426px`}>
          <Card
            dataTestId="left"
            {...pair[0]}
            isFront={isFront}
            isFlipping={isFlipping}
            toFront={toFront}
            fadingOut={fadingOut}
            onSelect={() => selectHandler(0)}
          />
        </div>
        <div className={`w-342px sm:w-426px`}>
          <Card
            dataTestId="right"
            {...pair[1]}
            isFront={isFront}
            isFlipping={isFlipping}
            toFront={toFront}
            fadingOut={fadingOut}
            onSelect={() => selectHandler(1)}
          />
        </div>
      </div>
      <div className="sm:h-94px pb-6 sm:pb-10 w-full align-middle text-center">
        <Button
          secondary={!isFront}
          text={isFront ? t("button-label") : t("button-alternate-label")}
          onClick={showDescriptionHandler}
          disabled={isFlipping}
        />
      </div>
    </>
  );
}

export function Card({
  gender,
  trait,
  traitChanceLevel,
  condition,
  conditionChanceLevel,
  chosen,
  onSelect,
  isFront,
  isFlipping,
  toFront,
  fadingOut,
  size = "large",
  sectionOrder,
  dataTestId,
}) {
  const { t } = useTranslation("card");

  const sizedClasses =
    size === "large"
      ? "w-342px sm:w-426px text-3.5 sm:mx-auto"
      : "w-171px sm:w-252px leading-9px text-2 m-2 ";

  const cardProps = {
    gender,
    trait,
    traitChanceLevel,
    condition,
    conditionChanceLevel,
    chosen,
    onSelect,
    size,
    sectionOrder,
    t,
    dataTestId,
  };
  return (
    <div
      className={`flex flex-col bg-transparent ${sizedClasses}  font-inter animate-fade-in animate-duration-500 ${
        fadingOut && "animate-fade-out"
      }`}
    >
      <div
        className={`relative transition-transform duration-700 ${
          isFront ? "" : "transform rotate-y-180"
        }`}
      >
        {(isFront && !isFlipping) || (isFlipping && !toFront) ? (
          <CardFront {...cardProps} />
        ) : (
          <CardBack {...cardProps} />
        )}
      </div>
    </div>
  );
}

const Spacer = () => <div className="h-6.44px sm:h-2 w-full" />;
export function CardFront({
  gender,
  trait,
  traitChanceLevel,
  condition,
  conditionChanceLevel,
  chosen,
  onSelect,
  size = "large",
  sectionOrder,
  t,
  dataTestId,
}) {
  const sizedClasses =
    size === "large"
      ? "min-w-342px sm:min-w-426px h-270px sm:h-508px sm:py-6 sm:px-4 px-3 rounded-3"
      : "w-171px sm:w-252px sm:h-296px rounded-2 p-2";

  return (
    <div
      className={`${sizedClasses} bg-tinkerTots-white flex flex-col max-sm:active:(outline-tinkerTots-primary-main outline-4 outline-solid) sm:hover:(outline-tinkerTots-primary-main outline-4 outline-solid) ${
        chosen ? "outline-tinkerTots-primary-main outline-4 outline-solid" : ""
      }`}
      style={{ boxShadow: "0px 1.61px 16.103px 0px rgba(0, 0, 0, 0.10)" }}
      onClick={onSelect}
      data-testid={dataTestId}
    >
      <div className="hidden sm:block">
        <CardHeaderRow
          size={size}
          middle={
            <img
              className="h-full w-auto"
              src="images/embryo.png"
              alt="embryo"
            />
          }
        />
      </div>
      {sectionOrder.map((section, ii) => {
        switch (section) {
          case "gender":
            return (
              <GenderRow
                key={section}
                size={size}
                middleImage={genderImages[gender]}
                middleAlt={t(`genders.${forTranslation(gender)}`)}
                rightText={t(`genders.${forTranslation(gender)}`)}
                topSpacer={ii !== 2}
                tooltipText={t(`genders.${forTranslation(gender)}`)}
              />
            );
          case "condition":
            return (
              <CardRow
                key={section}
                size={size}
                leftText={t(`attributes.${forTranslation(condition)}.label`)}
                leftImage={attributesMap[condition].image}
                leftImageAlt={t(
                  `attributes.${forTranslation(condition)}.label`
                )}
                rightText={t(
                  `chanceLevels.${forTranslation(conditionChanceLevel)}`
                )}
                rightImage={chanceLevelImages[conditionChanceLevel]}
                topSpacer={ii !== 2}
                tooltipText={t(
                  `attributes.${forTranslation(condition)}.description`
                )}
              />
            );
          case "trait":
            return (
              <CardRow
                key={section}
                size={size}
                leftText={t(`attributes.${forTranslation(trait)}.label`)}
                leftImage={attributesMap[trait].image}
                leftImageAlt={t(`attributes.${forTranslation(trait)}.label`)}
                rightText={t(
                  `chanceLevels.${forTranslation(traitChanceLevel)}`
                )}
                rightImage={chanceLevelImages[traitChanceLevel]}
                topSpacer={ii !== 2}
                tooltipText={t(
                  `attributes.${forTranslation(trait)}.description`
                )}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
}

const genderDescription = (gender) =>
  `is predicted to be <strong>${gender}</strong>.`;

function CardBack({
  gender,
  trait,
  traitChanceLevel,
  condition,
  conditionChanceLevel,
  onSelect,
  sectionOrder,
  chosen,
  size = "large",
  t,
}) {
  const sizedClasses =
    size === "large"
      ? "sm:min-w-342px sm:min-w-426px h-270px sm:h-508px sm:py-6 sm:px-4 px-5 pt-5 rounded-3 leading-5"
      : "w-171px sm:w-252px sm:h-296px text-2.5 leading-4.5 rounded-2 p-3";

  const BackRow = ({ label, text }) => (
    <div className="flex pb-3">
      <span
        className="sm:font-600"
        dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(text) }}
      />
    </div>
  );
  const conditionText = `${t(
    `attributes.${forTranslation(condition)}.description`
  )}`;
  const traitText = `${t(`attributes.${forTranslation(trait)}.description`)}`;
  const traitPreceding = attributesMap[trait].preceding;
  const conditionPreceding = attributesMap[condition].preceding;
  const precedingText = (preceding) =>
    preceding === Preceding.BEING
      ? t("being")
      : preceding === Preceding.NONE
      ? ""
      : t("having");

  return (
    <>
      <div
        className={`font-sans sm:text-4 ${sizedClasses} text-tinkerTots-white bg-tinkerTots-black flex flex-col transform-rotate-y-180 ${
          chosen
            ? "outline-tinkerTots-primary-main outline-4 outline-solid"
            : ""
        }`}
        style={{ boxShadow: "0px 1.61px 16.103px 0px rgba(0, 0, 0, 0.10)" }}
      >
        {sectionOrder.map((section, ii) => {
          const subject = ii === 0 ? "This embryo" : "It";
          switch (section) {
            case "gender":
              return (
                <BackRow
                  key={section}
                  label={t("gender")}
                  text={`${subject} ${genderDescription(gender)}`}
                />
              );
            case "condition":
              return (
                <BackRow
                  key={section}
                  label={t("condition")}
                  text={`${subject} has ${t(
                    `chanceLevels.${forTranslation(conditionChanceLevel)}`
                  )} of ${t("description", {
                    preceding: precedingText(conditionPreceding),
                    name: t(`attributes.${forTranslation(condition)}.label`),
                    description: conditionText,
                  })}`}
                />
              );
            case "trait":
              return (
                <BackRow
                  key={section}
                  label={t("trait")}
                  text={`${subject} has has ${t(
                    `chanceLevels.${forTranslation(traitChanceLevel)}`
                  )} of ${t("description", {
                    preceding: precedingText(traitPreceding),
                    name: t(`attributes.${forTranslation(trait)}.label`),
                    description: traitText,
                  })}`}
                />
              );
            default:
              return null;
          }
        })}
      </div>
    </>
  );
}
export function CardHeaderRow({ size, left, middle, right }) {
  const sizedClasses =
    size === "large"
      ? "h-116px sm:h-144px sm:w-144px py-2px"
      : "w-233px h-85px gap-1";
  return (
    <div className="flex justify-center items-center">
      <div className="flex-grow" />
      <div
        className={`flex justify-center items-center w-auto ${sizedClasses}`}
      >
        {middle}
      </div>
      <div className="flex-grow" />
    </div>
  );
}

function CardRow({
  size,
  leftText,
  leftImage,
  leftImageAlt,
  rightImage,
  rightText,
  topSpacer = false,
  tooltipText = "",
}) {
  const sizedOuter =
    size === "large"
      ? "w-317px py-6.44px px-12.88px sm:(w-394px py-3 px-2)"
      : "w-233.07px py-4.73px px-9.46px";

  const sizedLeft =
    size === "large" ? "w-126.41px sm:(w-157px)" : "w-92.87px min-w-92.87px";

  const sizedLeftText =
    size === "large"
      ? "w-55.56px mr-6.44px text-9.66px leading-11.5px sm:(w-85px mr-3 text-3 leading-3.5)"
      : "w-40.82px text-7.1px leading-8.59px";

  const sizeLeftImage =
    size === "large"
      ? "h-64.41px w-64.41px sm:(h-80px w-80px)"
      : "h-47.32px w-47.32px";

  const sizedRightImage =
    size === "large"
      ? "h-64.41px w-19.32px mx-9.66px sm:(h-80px w-24px mx-3)"
      : "h-47.32px w-14.2px mx-7.1px";

  const sizedRightText =
    size === "large"
      ? "w-115px text-9.66px leading-11.5px sm:(text-3 leading-3.5) "
      : "w-92.87px text-7.1px leading-8.59px";
  return (
    <div>
      {topSpacer && <Spacer />}
      <div className={`flex justify-start items-center font-600 ${sizedOuter}`}>
        <div className={`flex justify-end items-center ${sizedLeft}`}>
          <div className={`${sizedLeftText} text-right`}>{leftText}</div>
          <Popup
            trigger={(open) => (
              <img
                className={`${sizeLeftImage}`}
                src={leftImage}
                alt={leftImageAlt}
              />
            )}
            on={["hover"]}
            position="top left"
            closeOnDocumentClick
            className="tooltip"
            lockScroll
          >
            <span>{tooltipText}</span>
          </Popup>
        </div>
        <div>
          <img
            src={rightImage}
            alt={rightText}
            className={`${sizedRightImage}`}
          />
        </div>
        <div
          className={`capitalize ${sizedRightText}`}
          dangerouslySetInnerHTML={{ __html: rightText }}
        />
      </div>
    </div>
  );
}
function GenderRow({
  size,
  leftText,
  middleImage,
  middleAlt,
  rightText,
  rightImage,
  topSpacer = false,
  tooltipText = "",
}) {
  const sizedClasses =
    size === "large"
      ? "h-76.22px sm:h-96px py-6.44px sm:px-4 sm:py-0"
      : "w-233px h-56px gap-1";
  const baseClassNames = `flex w-full justify-center items-center font-600 ${sizedClasses}`;
  const middlePanelClassNames = `flex justify-center items-center shrink-0 ${
    size === "large"
      ? "h-64px w-64px min-h-64px max-h-64px sm:min-h-80px sm:max-h-80px sm:w-80px"
      : "h-47.32px w-47.32px min-h-47.32px max-h-47.32px sm:min-h-47.32px sm:max-h-47.32px sm:w-47.32px"
  }`;
  const sidePanelBase = `flex ${
    size === "large" ? "w-114px sm:w-141px" : "w-76.31px mx-2"
  }`;
  const rightPanelClassNames = `flex justify-end ${sidePanelBase} ${
    size === "large" ? "mr-3 sm:mr-4" : ""
  }`;
  const leftPanelClassNames = `flex justify-start hyphens-auto text-wrap ${sidePanelBase} ${
    size === "large" ? "ml-3 sm:ml-3" : ""
  }`;
  return (
    <>
      {topSpacer && <Spacer />}
      <div className={`${baseClassNames}`}>
        <div className={`${rightPanelClassNames}`}>
          <LeftPanel size={size} text={leftText} />
        </div>
        <div className={`${middlePanelClassNames}`}>
          <MiddlePanel
            image={middleImage}
            alt={middleAlt}
            tooltip={tooltipText}
          />
        </div>
        <div className={`${leftPanelClassNames}`}>
          <RightPanel size={size} text={rightText} image={rightImage} />
        </div>
      </div>
    </>
  );
}

const MiddlePanel = ({ image, alt, tooltip }) => {
  if (tooltip) {
    return (
      <Popup
        trigger={(open) => (
          <img className="w-full h-auto" src={image} alt={alt} />
        )}
        on={["hover"]}
        position="top left"
        closeOnDocumentClick
        className="tooltip"
        lockScroll
      >
        <span>{tooltip}</span>
      </Popup>
    );
  }
  return <img className="w-full h-auto" src={image} alt={alt} />;
};

export function LeftPanel({ size, text, image }) {
  if (image) {
    return (
      <div className="flex items-center">
        <div
          className={`text-right capitalize hyphens-auto break-words ${
            size === "large" ? "min-w-93px" : "min-w-75px"
          }`}
        >
          {text}
        </div>
        <img
          src={image}
          alt={text}
          className={`${
            size === "large"
              ? "h-64px sm:h-full w-auto ml-3 sm:ml-4"
              : "h-47.32px w-auto ml-2"
          }`}
        />
      </div>
    );
  }
  return (
    <div
      className={`text-right capitalize hyphens-auto break-words ${
        size === "large" ? "max-w-141px" : "max-w-76.31px"
      }`}
    >
      {text}
    </div>
  );
}

export function RightPanel({ size, text, image }) {
  if (image) {
    return (
      <div className="flex items-center">
        <img
          src={image}
          alt={text}
          className={`${
            size === "large"
              ? "h-64px sm:h-full w-auto mr-3 sm:mr-4"
              : "h-47.32px w-auto mr-2"
          }`}
        />
        <div
          className={`text-left capitalize hyphens-none ${
            size === "large" ? "max-w-93px" : "max-w-75px"
          }`}
        >
          {text}
        </div>
      </div>
    );
  }
  return <div className="text-left capitalize hyphens-none">{text}</div>;
}
